import { FC } from 'react';
import { useRouter } from 'next/router';
import clx from 'classnames';
import { useSession } from 'lib/hooks/session';
import { EmployeeInCompany } from 'lib/types/company';
import { userFullName } from 'lib/utils/user';
import { Avatar } from 'components/atoms/Avatar';
import { DepartmentTag } from 'components/atoms/DepartmentTag';
import Search from 'components/molecules/Search';
import LoadingTableSkeleton from 'components/atoms/LoadingTableSkeleton';
import PageSizeSelector from 'components/atoms/PageSizeSelector';
import PageNavigator from 'components/atoms/PageNavigator';
import { Badge } from 'components/atoms/Badge';
import { getUserStatusName } from 'lib/types/user';
import { FilterToggle } from '../../../molecules/FilterToggle';
import FilterCard from '../../../organisms/FilterCard';
import EmployeeLocation from '../../../molecules/EmployeeLocation';
import { Location } from '../../../../lib/contexts/hr/company/domain/Location/Location';

type EmployeeTableProps = {
  loading: boolean;
  employees: EmployeeInCompany[];
  count: number;
  searchCount: number;
  pageSize: number;
  currentPage: number;
  onSearch: (q: string) => void;
  onPageChange: (page: number) => void;
  onSelect: (employee: EmployeeInCompany) => void;
  setPageSize: (pageSize: number) => void;
  showOnlyActiveUsers: boolean;
  onShowOnlyActiveChange: (showOnlyActive: boolean) => void;
  isAdmin: boolean;
};

const tableHeaders = ['name', 'role', 'team', 'location'];

function isLineManager(userID: string, employee: EmployeeInCompany) {
  return userID === employee.line_manager_id;
}

export const EmployeeTable: FC<EmployeeTableProps> = ({
  loading,
  employees,
  pageSize,
  onPageChange,
  currentPage,
  setPageSize,
  count,
  searchCount,
  onSearch,
  onSelect,
  showOnlyActiveUsers,
  onShowOnlyActiveChange,
  isAdmin,
}) => {
  const router = useRouter();
  const { session } = useSession();
  const pages = Math.ceil(searchCount / pageSize);
  const totalPages = pages <= 1 ? 1 : pages;

  function handleOnSelect(employee: EmployeeInCompany) {
    if (isLineManager(session.id, employee) || session.account_type !== 300) {
      return router.push(`/profile?employee_id=${employee.id}`);
    }
    onSelect(employee);
  }

  return (
    <>
      {isAdmin && (
        <FilterCard
          intercomId="employee-table-filter"
          className="mb-5"
          text="Show active employees only"
          filters={FilterToggle({
            value: showOnlyActiveUsers,
            onToggle: () => onShowOnlyActiveChange(!showOnlyActiveUsers),
          })}
          active={showOnlyActiveUsers}
        />
      )}
      <div className="w-full bg-white rounded-lg pb-10 shadow-sm ">
        <div className="flex justify-between p-7">
          <h2 className="text-xl font-bold"> {searchCount} employees </h2>
          <Search onSearch={onSearch} placeholder="Search by keyword..." />
        </div>

        <div className="grid grid-cols-4 mt-10">
          {tableHeaders.map((header) => (
            <div key={header} className="font-bold text-left capitalize px-10">
              {header}
            </div>
          ))}
        </div>
        <div className="pt-5">
          {!loading && !employees?.length && (
            <div className="text-center text-gray-500 mt-5">
              We couldn't find a match
            </div>
          )}
          {!loading &&
            employees?.map((employee, i) => (
              <div
                key={employee.id}
                onClick={() => handleOnSelect(employee)}
                className={clx('grid grid-cols-4 px-10 py-5 cursor-pointer', {
                  'bg-gray-50': i % 2 === 0,
                })}
              >
                <div className="flex items-center">
                  <div className="h-12 w-12 ">
                    <Avatar
                      first_name={employee.first_name}
                      last_name={employee.last_name}
                      size="medium"
                    />
                  </div>
                  <div className="flex flex-col ml-3">
                    <span className="font-bold">
                      {userFullName(employee as any)}
                    </span>
                    {employee.status !== 1 && (
                      <span className="my-1">
                        <Badge size="small">
                          {getUserStatusName(employee.status)}
                        </Badge>
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex items-center font-light text-gray-800 pl-5">
                  {employee.job_title}
                </div>

                <div className="flex items-center pl-10">
                  {employee.department && (
                    <DepartmentTag
                      label={employee.department?.name}
                      color={employee.department?.color}
                    />
                  )}
                </div>

                <div className="flex items-center font-light text-gray-800 pl-14">
                  <EmployeeLocation
                    textSize={'text-base'}
                    iconSize={'sm'}
                    location={
                      employee?.user_location
                        ? Location.fromPrisma({
                            ...employee?.user_location,
                            id: '',
                            company_id: '',
                          })
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}
          <LoadingTableSkeleton loading={loading} />
        </div>

        <div className="flex justify-between w-full px-10 mt-10">
          <PageSizeSelector pageSize={pageSize} onChange={setPageSize} />

          {employees?.length ? (
            <PageNavigator
              onChange={onPageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
