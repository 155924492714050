'use client';
import { SearchIcon } from '@heroicons/react/outline';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { ChangeEvent, FC, useState, useCallback } from 'react';

interface SearchProps {
  onSearch?: (input: string) => void;
  placeholder: string;
}

const Search: FC<SearchProps> = ({ onSearch = () => {}, placeholder = '' }) => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace } = useRouter();
  const params = new URLSearchParams(searchParams);
  const [query, setQuery] = useState<string>(params.get('query') || '');

  let timeoutId = null;
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        const _params = new URLSearchParams(searchParams);
        if (newValue) {
          _params.set('query', newValue);
        } else {
          _params.delete('query');
        }
        replace(`${pathname}?${_params.toString()}`);
        timeoutId = null;
      }, 800);

      setQuery(newValue);
      if (onSearch) {
        onSearch(newValue);
      }
    },
    [setQuery, onSearch, timeoutId, searchParams, pathname],
  );

  return (
    <div className="flex">
      <div className="flex justify-center items-center">
        <SearchIcon className="w-5 h-5 text-gray-400 mr-2" />
      </div>
      <input
        className="w-80 py-2"
        placeholder={placeholder}
        value={query}
        onChange={onChange}
      />
    </div>
  );
};

export default Search;
