import { useEffect, useMemo, useState } from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';

import { Tab } from '@headlessui/react';
import { EmployeeInCompany } from 'lib/types/company';
import { useCompanyEmployees } from 'lib/hooks/company/useCompanyEmployees';
import { EmployeeTable } from 'components/scenes/company/EmployeeTable';
import { OrganizationalChart } from 'components/scenes/company/OrganizationChart';
import { useCompanyOrgChart } from 'lib/hooks/company/useCompanyOrgChart';
import { useProfile } from 'lib/hooks/useProfile';
import { useSession } from 'lib/hooks/session';
import PersonalDetailsModal from 'components/molecules/PersonalDetailsModal';
import { isEmployee, isLineManager } from 'lib/utils/user';
import { AccountType, UserStatus } from 'lib/types/user';
import { Button } from '../../components/atoms/Button';
import { useCompany } from 'lib/hooks/company/useCompany';
import { LoadingSpinner } from '../../components/atoms/LoadingSpinner';
import CompanySettingsScene from '../../components/scenes/company/Settings';
import { PlusCircleIcon } from '@heroicons/react/outline';

enum TabSelected {
  PEOPLE = 0,
  ORG_CHART = 1,
  SETTINGS = 2,
}

export default function Company() {
  const router = useRouter();
  const query = router.query;
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeInCompany>(null);
  const [selectedIndex, setSelectedIndex] = useState<TabSelected>(
    TabSelected.PEOPLE,
  );
  const queryTab = query.tab as string;

  const [currentUser, setCurrentUser] = useState(null);
  const [showOnlyActiveUsers, setShowOnlyActiveUsers] = useState<boolean>(true);

  const { session } = useSession();
  const { user } = useProfile({ employee_id: session?.id });
  const isAdmin = !isEmployee(user?.account_type);

  const {
    data,
    loading,
    refetch: refetchEmployees,
  } = useCompanyEmployees({
    q: search.trim(),
    limit: pageSize,
    offset: currentPage === 1 ? 0 : (currentPage - 1) * pageSize,
    status: showOnlyActiveUsers ? [UserStatus.Active] : undefined,
  });

  const { data: allUsersData } = useCompanyOrgChart();
  const { data: company, loading: loadingCompany } = useCompany();

  function handleOpenModal(employee: EmployeeInCompany) {
    setSelectedEmployee(employee);
    setShowModal(true);
  }

  function handleCloseModal() {
    setShowModal(false);
    setTimeout(() => {
      setSelectedEmployee(null);
    }, 250);
  }

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }

    if (queryTab === 'org_chart') {
      setSelectedIndex(TabSelected.ORG_CHART);
    }
    if (queryTab === 'settings') {
      setSelectedIndex(TabSelected.SETTINGS);
    }
  }, [queryTab, user]);

  const onShowOnlyActiveChange = (isActive: boolean) => {
    setShowOnlyActiveUsers(isActive);
    setCurrentPage(1);
    refetchEmployees();
  };

  const canShowProfile = useMemo(() => {
    if (selectedEmployee && currentUser) {
      if (
        isLineManager(currentUser.id, selectedEmployee) ||
        currentUser.account_type !== AccountType.Employee
      ) {
        return true;
      }
      return false;
    }
  }, [selectedEmployee, currentUser]);

  useEffect(() => {
    if (selectedIndex === TabSelected.SETTINGS) {
      window.history.pushState(null, '', `/company?tab=settings`);
    } else if (selectedIndex === TabSelected.ORG_CHART) {
      window.history.pushState(null, '', `/company?tab=org_chart`);
    } else {
      window.history.pushState(null, '', `/company`);
    }
  }, [selectedIndex]);

  return (
    <>
      <NextSeo title="Company" />
      <div className="pb-20">
        <div className="flex justify-between mb-24">
          <h1 className="text-3xl font-bold"> Company </h1>
          {!isEmployee(user) && (
            <div className="mt-2">
              <div className="justify-end flex pb-2">
                <Button
                  className="font-normal"
                  onClick={() => router.push('/company/people/edit')}
                >
                  <PlusCircleIcon width={20} height={20} className="mr-2" />
                  Add new team members
                </Button>
              </div>
            </div>
          )}
        </div>
        <PersonalDetailsModal
          canShowProfile={canShowProfile}
          handleCloseModal={handleCloseModal}
          showModal={showModal}
          selectedEmployee={selectedEmployee}
        />

        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="border-b text-lg border-brand-gray-500">
            <Tab className="mr-6 w-32 font-medium">
              {({ selected }) => (
                <h2
                  className={
                    selected ? 'text-black font-bold' : 'text-gray-400'
                  }
                >
                  People
                  {selected && (
                    <div className="h-1 bg-black relative mr-8 w-full mt-1" />
                  )}
                </h2>
              )}
            </Tab>
            <Tab className="font-medium text-lg mr-6 min-w-32">
              {({ selected }) => (
                <h2
                  className={
                    selected ? 'text-black font-bold' : 'text-gray-400'
                  }
                >
                  Organizational Chart
                  {selected && (
                    <div className="h-1 bg-black relative mr-8 w-full mt-1" />
                  )}
                </h2>
              )}
            </Tab>
            {isAdmin && (
              <Tab className="font-medium text-lg mr-6 w-32">
                {({ selected }) => (
                  <h2
                    className={
                      selected ? 'text-black font-bold' : 'text-gray-400'
                    }
                  >
                    Settings
                    {selected && (
                      <div className="h-1 bg-black relative mr-8 w-full mt-1" />
                    )}
                  </h2>
                )}
              </Tab>
            )}
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-10">
                <EmployeeTable
                  isAdmin={isAdmin}
                  loading={loading}
                  count={data?.count ?? 0}
                  searchCount={data?.searchCount ?? 0}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  employees={data?.employees}
                  onSearch={(s) => setSearch(s.trim())}
                  onPageChange={setCurrentPage}
                  onSelect={handleOpenModal}
                  showOnlyActiveUsers={showOnlyActiveUsers}
                  onShowOnlyActiveChange={onShowOnlyActiveChange}
                />
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <section className="mt-10">
                {loadingCompany && (
                  <div className="flex justify-center items-center">
                    <LoadingSpinner />
                  </div>
                )}
                {!loadingCompany && (
                  <OrganizationalChart
                    company={company}
                    count={allUsersData?.count ?? 0}
                    employees={allUsersData?.employees}
                    currentUser={currentUser}
                  />
                )}
              </section>
            </Tab.Panel>
            {isAdmin && (
              <Tab.Panel>
                <section className="mt-10">
                  <CompanySettingsScene />
                </section>
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
